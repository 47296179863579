import React from 'react';
import Contact from '../Home/contact/Contact'; // Assurez-vous que le chemin vers Contact.js est correct
import './ContactPage.css';

const ContactPage = () => {
  return (
    <div className='contact-page'>
      {/* <h1>Page de Contact</h1> */}
      <Contact />
    </div>
  );
};

export default ContactPage;
