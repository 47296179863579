import React from 'react';
import Avis from './Avis';
import data from '../../assets/data.json';
import { Link } from 'react-router-dom'; 
import './AvisAll.css';



// Import des images
import md1 from '../../assets/projets/md1.png'
import ave1 from '../../assets/projets/ave1.png';
import ga1 from '../../assets/projets/ga1.png';
import fsa1 from '../../assets/projets/fsa1.png';
import rc1 from '../../assets/projets/rc1.png';
import sp1 from '../../assets/projets/sp1.png';
import yt1 from '../../assets/projets/yt1.png';
import pa1 from '../../assets/projets/pa1.png';
import lm1 from '../../assets/projets/lm1.png';
import ft1 from '../../assets/projets/ft1.png';
import lad1 from '../../assets/projets/lad1.png';
import rd1 from '../../assets/projets/rd1.png';

const AvisAll = () => {
  // Filtrer les avis non nuls
  const avisNonNuls = data.filter(item => item.avis !== null);
  avisNonNuls.sort((a, b) => b.id - a.id);
  return (
    <div className="avis-all-container">
      <h2>Avis</h2>
      {avisNonNuls.map((projet, index) => (
        // <Link to={`/projets/${projet.id}`} key={index}> 
        <Avis
          key={index}
          projet={projet}
          image={getImageForId(projet.id)}
          background={index % 2 === 0 ? 'light' : 'dark'}
          showProjectLink={true}
        />
        //  </Link>
      ))}
    </div>
  );
};

const getImageForId = (projectId) => {
  switch (projectId) {
    case 12:
      return rd1;
    case 11:
      return lad1;
    case 10:
      return ft1;
    case 9:
      return lm1;
    default:
      return null;
  }
};

export default AvisAll;
