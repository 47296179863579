import React, { useState, useEffect } from 'react';
import "./Banner.css";

import creation from '../../../assets/img/creation-website.png';
import dev from '../../../assets/img/dev-website.png';
import maintenance from '../../../assets/img/maintenance-website.png';
import refonte from '../../../assets/img/refonte-website.png';

import iconEmail from '../../../assets/icon/email.png';
import iconMarker from '../../../assets/icon/marker.png';

const Banner = () => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [opacity, setOpacity] = useState(1);
    const images = [creation, maintenance, dev, refonte];
    const texts = ["Création de site web", "Maintenance", "Développements spécifiques", "Refonte de site"];

     // Mots à mettre en gras en fonction de l'image affichée
     const keywords = ["création de sites web", "maintenance", "développement de fonctionnalités", "refonte de sites"];

     // Texte de la phrase
     const sentence = "Spécialiste en création de sites web, maintenance, développement de fonctionnalités sur mesure et refonte de sites.";
 
     // Fonction pour mettre en gras le mot correspondant à l'image actuelle
     const highlightKeyword = (sentence, index) => {
         const keyword = keywords[index];
         const regex = new RegExp(`(${keyword})`, 'gi');
         // Remplace le mot par une version avec style en gras
         return sentence.replace(regex, `<span class="highlight">$1</span>`);
     };

    useEffect(() => {
        const interval = setInterval(() => {
            setOpacity(0);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
                setOpacity(1);
            }, 800);
        }, 4000);

        return () => clearInterval(interval);
    }, []);


    return (
        <div className='banner-container'>

            <div className='banner-infos-container'>

                {/* <h1>Conception de sites web </h1> */}
                <div className='banner-infos'>

                    <img />

                    <img />
                    <h2>Webmaster & <br /> Développement Web</h2>
                    {/* <p>Solutions complètes en développement informatique et réalisation de site vitrine, e-commerce, portfolio, événementiel, intranet ...</p> */}
                    {/* <p>Création de sites sur mesure, refonte de sites existants, développement de fonctionnalités spécifiques, et maintenance de site internet.</p> */}
                    {/* <p>Spécialiste en <em>création de sites web personnalisés</em>, <em>refonte de sites</em>, <em>développement de fonctionnalités sur mesure</em> et <em>maintenance de sites internet</em>.</p> */}
                    {/* <p>Spécialiste en création de sites web, refonte de sites, développement de fonctionnalités sur mesure et maintenance</p> */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: highlightKeyword(sentence, currentIndex)
                      }}>
                    </p>

                    <div className='banner-imgs banner-imgs-small' style={{ opacity }}>
                    <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} />
                    {/* <h5>{texts[currentIndex]}</h5> */}
                </div>

                    <img />
                    <a href="#contact" className='banner-button'>
                            Discutons de votre projet
                    </a>
                    <img />
                </div>

                <div className='banner-imgs banner-imgs-big' style={{ opacity }}>
                    <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} />
                    {/* <h5>{texts[currentIndex]}</h5> */}
                </div>

            </div>
        </div>

    );
};

export default Banner;
